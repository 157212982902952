import { useTranslation, Trans } from '@wix/yoshi-flow-editor';
import React from 'react';
import {
  getGroupCancellationFeeDetails,
  isRescheduleAble,
} from '../../../../../utils/bookInfo';
import { BookingsGroup, CloseModalTriggers } from '../../../../../types';
import { CancellationModalDataHooks } from '../../../../../components/MyBookings/Widget/datahooks';
import { Modal } from '../../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../../hooks/useApplicationContext';
import Link from '../../../../../components/MyBookings/Components/Link';

export interface CancellationFeeModalContentProps {
  group: BookingsGroup;
}

export const CancellationFeeModalContent: React.FC<
  CancellationFeeModalContentProps
> = ({ group }) => {
  const { actions, businessInfo } = useApplicationContext();
  const { t } = useTranslation();
  const groupCancellationFeeDetails = getGroupCancellationFeeDetails(
    group,
    businessInfo?.dateRegionalSettingsLocale || '',
  );

  const handleCancelButtonClick = () => {
    actions.clickOnCancel({
      group,
    });
    actions.closeModal();
  };

  const handleCloseButtonClick = () => {
    actions.closeModal(CloseModalTriggers.CloseModal);
  };

  const renderCancellationFeeModalContent = () => (
    <Modal
      dataHook={CancellationModalDataHooks.CancellationFee}
      modalTitle={t('app.my-bookings-widget.cancellation-fee-modal.title')}
      modalSubtitle={
        <Trans
          i18nKey="app.my-bookings-widget.cancellation-fee-modal.content"
          values={{
            amount: groupCancellationFeeDetails?.formattedValue,
          }}
        >
          <Link />
        </Trans>
      }
      primaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-fee-modal.primary-button.label',
          ),
          onClick: handleCancelButtonClick,
        },
      }}
      secondaryButton={{
        context: {
          label: t(
            'app.my-bookings-widget.cancellation-fee-modal.secondary-button.label',
          ),
          onClick: handleCloseButtonClick,
        },
      }}
    />
  );

  const renderCancellationFeeModalWithRescheduleContent = () => (
    // TODO
    <div></div>
  );

  const renderModalContent = () => {
    if (isRescheduleAble(group)) {
      return renderCancellationFeeModalWithRescheduleContent();
    } else {
      return renderCancellationFeeModalContent();
    }
  };

  return renderModalContent();
};
